/* eslint-disable @typescript-eslint/no-use-before-define */
import { Outlet } from 'react-router-dom';
import CartIcon from '../../../assets/images/cart-icon.svg';
import {useRingCustomizerContext} from "../../context/RingCustomizerContext";
import clsx from 'clsx';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';
import If from '../../components/If';

const RingCustomizerLayout = () => {
  const {subpath} = useRingCustomizerContext();

  const styles = APP_STYLES[subpath] || DEFAULT_STYLES;

  return (
    <div 
      className={clsx(`tw-flex tw-justify-between tw-px-0 tw-h-12 tw-w-full tw-top-0`, { 
        'tw-bg-brand-color lg:tw-bg-primary-bg-color': styles.topBar.invertColor,
        'tw-bg-primary-bg-color': !styles.topBar.invertColor,
        'tw-hidden': !styles.showTopBar
      })}
      style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="tw-flex tw-items-center tw-cursor-pointer">
        <a href='tw-/'>
          <img className={clsx('brand-logo tw-mx-2 lg:tw-mx-5', {
            'tw-invert lg:tw-invert-0': styles.topBar.invertColor
          })} alt="brand_logo" />
        </a>
      </div>
      <If condition={styles.topBar.showTopContent}>
        <div className='tw-text-white lg:tw-text-black tw-text-center tw-py-2.5 tw-text-xl lg:tw-block tw-w-full'>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm tw-font-extrabold'>🖐️ FREE home sizing kit</span>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm'>with every order!</span>

          {/* <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm'>
            <b>25% OFF</b> <span className="tw-font-normal">with code:</span> <b>BESTDEAL</b>
          </span> */}
          
          {/* <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm xs:tw-hidden lg:tw-inline'>💚 Use Code:</span>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm tw-font-extrabold'>SAYYES</span>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm xs:tw-hidden lg:tw-inline'>for</span>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm tw-font-extrabold'>20% Off</span>
          <span className='tw-pl-1.5 tw-text-white lg:tw-text-black tw-text-sm'>through Feb 14 💚</span> */}
        </div>
        <a href="https://wearemblem.com/cart" className="tw-p-3 xs:tw-hidden lg:tw-flex lg:tw-justify-end"><img src={CartIcon} alt="cart_icon" /></a>
      </If>
    </div>)
}

export default RingCustomizerLayout;
