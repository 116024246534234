import { ReactNode } from "react";
import SwatchFilter from "../app/components/SwatchFilter";
import { ISwatchContent, ITab } from "../app/types/customizer";
import { getFilterMetaData, getFontOptions, getSelectedOption, getWarning } from "./commonRingUtils";
import crossWhite from '../assets/images/cross-white.svg';
import crossBlack from '../assets/images/cross-black.svg';
import EngravingFilter from "../app/components/EngravingFilter/EngravingFilter";
import EngravingNoneTabFilter from "../app/components/EngravingNoneTabFilter";

export const getEngravingItems = (product: any, engraving: any) => {
    if (!engraving) {
        return {};
    }
    const engravingOptions = engraving.types.options || [];
    const SKU_LIST: string[] = engravingOptions.map((item: any) => item.sku);
    const selectedSKU = engravingOptions.find((item: any) => item.selected);
    const obj: {content?: ISwatchContent, items?: ITab[], selectedItem: string, component?: (props: any) => ReactNode} = {
        component: undefined,
        content: undefined,
        items: undefined,
        selectedItem: '',
    };
    if (SKU_LIST.includes('NONE')) {
        obj.selectedItem = 'none';
        obj.component = EngravingNoneTabFilter;
        obj.content = {
            label: 'None Tab',
            hasSwatch: false,
            showDefaultTitle: false,
            displayShowMoreOption: false,
            fieldType: 'engraving_new',
            options: engravingOptions,
            selectedOption: selectedSKU,
            meta: null,
            warning: '',
            isEnabled: true,
        };
    }
    if (SKU_LIST.includes('D') && selectedSKU.sku === 'D') {
        obj.selectedItem = 'pattern';
        obj.items = [
            {
                title: (isActive?: boolean) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
                eventKey: 'engraving_none',
                isSelected: selectedSKU?.sku === 'NONE',
                component: undefined,
                actionPayload: { engraving_none: 'NONE' },
                content: {
                    label: 'None Tab',
                    hasSwatch: false,
                    showDefaultTitle: false,
                    displayShowMoreOption: false,
                    fieldType: 'engraving_new',
                    options: [],
                    selectedOption: selectedSKU.sku === 'NONE' ? { name: 'None' } : '',
                    meta: null,
                    warning: '',
                    isEnabled: true,
                },
            },
            {
                title: 'Pattern',
                eventKey: 'engraving_pattern',
                isSelected: selectedSKU?.sku === 'D',
                actionPayload: { engraving_new: 'D' },
                component: SwatchFilter,
                content: {
                    label: 'Engraving Pattern',
                    hasSwatch: true,
                    showDefaultTitle: true,
                    displayShowMoreOption: false,
                    fieldType: 'engraving_pattern',
                    options: getFontOptions(engraving?.design_patterns?.options || []),
                    selectedOption: getSelectedOption(engraving?.design_patterns?.options),
                    meta: getFilterMetaData(product, 'engraving'),
                    warning: getWarning(product, 'engraving'),
                    isEnabled: engraving?.design_patterns?.options?.length > 0,
                },
            },
        ];
    }

    if (SKU_LIST.includes('T') && selectedSKU.sku === 'T') {
        obj.selectedItem = 'text';
        obj.items = [
            {
                title: (isActive?: boolean) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
                eventKey: 'engraving_none',
                isSelected: selectedSKU?.sku === 'NONE',
                component: undefined,
                actionPayload: { engraving_none: 'NONE' },
                content: {
                    label: 'None Tab',
                    hasSwatch: false,
                    showDefaultTitle: false,
                    displayShowMoreOption: false,
                    fieldType: 'engraving_new',
                    options: [],
                    selectedOption: selectedSKU.sku === 'NONE' ? { name: 'None' } : '',
                    meta: null,
                    warning: '',
                    isEnabled: true,
                },
            },
            {
                title: 'Text',
                eventKey: 'engraving',
                isSelected: selectedSKU?.sku === 'T',
                component: EngravingFilter,
                actionPayload: { engraving_new: 'T' },
                content: {
                    label: 'Engraving Font',
                    hasSwatch: false,
                    showDefaultTitle: true,
                    displayShowMoreOption: false,
                    fieldType: 'engraving_new',
                    options: getFontOptions(engraving?.text?.fonts || []),
                    selectedOption: getSelectedOption(engraving?.text?.fonts),
                    meta: getFilterMetaData(product, 'engraving'),
                    warning: getWarning(product, 'engraving'),
                    isEnabled: engraving?.text?.fonts?.length > 0,
                    engravingText: engraving?.text?.text,
                },
            },
        ];
    }
    return obj;
};
