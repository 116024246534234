import { ReactNode } from "react";
import SwatchFilter from "../app/components/SwatchFilter";
import { ISwatchContent, ITab, ITabs } from "../app/types/customizer";
import { getFilterMetaData, getSelectedOption, getWarning } from "./commonRingUtils";
import crossWhite from '../assets/images/cross-white.svg';
import crossBlack from '../assets/images/cross-black.svg';
import NoneTabFilter from "../app/components/NoneTabFilter";

export const getSecondaryOutsideFeatureItems = (product: any) => {
  const group = product.feature_groups?.[1];
  if(!group) return {};

  const { feature_group: feature_group_2 = [] } = group;
  const SKU_LIST: string[] = feature_group_2.map((item: any) => item.sku);
  const selectedSKU = feature_group_2.find((item: any) => item.selected);

  const obj: {content?: ISwatchContent, items?: ITab[], selectedFeature?:string, component?: (props: any) => ReactNode; } = {
    content: undefined,
    items: undefined
  }


  if(SKU_LIST.includes('N') && selectedSKU?.sku === 'N') {
    obj.component = NoneTabFilter;
    obj.content = {
      label: 'None Tab',
      hasSwatch: true,
      showDefaultTitle: false,
      displayShowMoreOption: false,
      fieldType: "feature_group_2",
      options: feature_group_2,
      selectedOption: getSelectedOption(feature_group_2),
      meta: null,
      warning: '',
      isEnabled: true,
    }
  }
  if(SKU_LIST.includes('G') && selectedSKU?.sku === 'G') {
    obj.items = getInlayItems(product, group);
    obj.selectedFeature = "Inlay";
  }
  if(SKU_LIST.includes('M') && selectedSKU?.sku === 'M') {
    obj.items = getDesignPatternItems(product, group);
    obj.selectedFeature = "Design Pattern";
  }
  if(SKU_LIST.includes('D') && selectedSKU?.sku === 'D') {
    obj.items = getGemstoneItems(product, group);
    obj.selectedFeature = "Gemstone";
  }
  if(SKU_LIST.includes('A') && selectedSKU?.sku === 'A') {
    obj.items = getCerokoteItems(product, group);
    obj.selectedFeature = "All Cerakote";
  }
  return obj;
}


const getDesignPatternItems = (product: any, group: any) => {
  const items: ITabs = [
    {
      title: (isActive) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
      eventKey: 'none',
      actionPayload: { feature_group_2: 'N' },
      content: {
        label: 'None Tab',
        hasSwatch: false,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "feature_group_2",
        options: [],
        selectedOption: null,
        meta: null,
        warning: '',
        isEnabled: true,
      }
    },
    {
      title: 'Pattern',
      eventKey: 'pattern',
      component: SwatchFilter,
      isSelected: true,
      content: {
        label: 'Design Pattern',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.machine_pattern?.length > 0,
        fieldType: "machine_pattern",
        options: group.machine_pattern || [],
        selectedOption: getSelectedOption(group.machine_pattern),
        meta: getFilterMetaData(product, 'machine_pattern'),
        warning: getWarning(product, 'machine_pattern')
      }
    },
    {
      title: 'Accent',
      eventKey: 'accent',
      component: SwatchFilter,
      content: {
        label: 'Accent Color',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false, // show more removed after review
        isEnabled: group.machine_pattern_option?.length > 0,
        fieldType: "machine_pattern_option",
        options: group.machine_pattern_option || [],
        selectedOption: getSelectedOption(group.machine_pattern_option),
        meta: getFilterMetaData(product, 'machine_pattern_option'),
        warning: getWarning(product, 'machine_pattern_option')
      }
    }
  ]
    const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}

const getGemstoneItems = (product: any, group: any) => {
  const items: ITabs = [
    {
      title: (isActive) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
      eventKey: 'none',
      actionPayload: { feature_group_2: 'N' },
      content: {
        label: 'None Tab',
        hasSwatch: false,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "feature_group_2",
        options: [],
        selectedOption: null,
        meta: null,
        warning: '',
        isEnabled: true,
      }
    },
    {
      title: 'Arrangement',
      eventKey: 'arrangement',
      component: SwatchFilter,
      isSelected: true,
      content: {
        label: 'Gemstone Arrangement',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.gemstone_arrangement?.length > 0,
        fieldType: "gemstone_arrangement_2",
        options: group.gemstone_arrangement || [],
        selectedOption: getSelectedOption(group.gemstone_arrangement),
        meta: getFilterMetaData(product, 'gemstone_arrangement'),
        warning: getWarning(product, 'gemstone_arrangement')
      }
    },
    {
      title: 'Color',
      eventKey: 'color',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Color',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.gemstone_material?.length > 0,
        fieldType: "gemstone_material_2",
        options: group.gemstone_material || [],
        selectedOption: getSelectedOption(group.gemstone_material),
        meta: getFilterMetaData(product, 'gemstone_material'),
        warning: getWarning(product, 'gemstone_material')
      }
    },
    {
      title: 'Shape',
      eventKey: 'shape',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Shape',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.gemstone_cut?.length > 0,
        fieldType: "gemstone_cut_2",
        options: group.gemstone_cut || [],
        selectedOption: getSelectedOption(group.gemstone_cut),
        meta: getFilterMetaData(product, 'gemstone_cut'),
        warning: getWarning(product, 'gemstone_cut')
      }
    },
    {
      title: 'Size',
      eventKey: 'size',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Size',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.gemstone_size?.length > 0,
        fieldType: "gemstone_size_2",
        options: group.gemstone_size || [],
        selectedOption: getSelectedOption(group.gemstone_size),
        meta: getFilterMetaData(product, 'gemstone_size'),
        warning: getWarning(product, 'gemstone_size')
      }
    },
    {
      title: 'Setting',
      eventKey: 'setting',
      component: SwatchFilter,
      content: {
        label: 'Gemstone Setting Style',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.gemstone_setting?.length > 0,
        fieldType: "gemstone_setting_2",
        options: group.gemstone_setting || [],
        selectedOption: getSelectedOption(group.gemstone_setting),
        meta: getFilterMetaData(product, 'gemstone_setting'),
        warning: getWarning(product, 'gemstone_setting')
      }
    }
  ]
    const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}

const getInlayItems = (product: any, group: any) => {
  const items: ITabs = [
    {
      title: (isActive) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
      eventKey: 'none',
      actionPayload: { feature_group_2: 'N' },
      content: {
        label: 'None Tab',
        hasSwatch: false,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "feature_group_2",
        options: [],
        selectedOption: null,
        meta: null,
        warning: '',
        isEnabled: true,
      }
    },
    {
      title: 'Placement',
      eventKey: 'placement',
      component: SwatchFilter,
      isSelected: true,
      content: {
        label: 'Inlay Style',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.groove_style?.length > 0,
        fieldType: "groove_style_2",
        options: group.groove_style || [],
        selectedOption: getSelectedOption(group.groove_style),
        meta: getFilterMetaData(product, 'groove_style'),
        warning: getWarning(product, 'groove_style')
      }
    },
    {
      title: 'Width',
      eventKey: 'width',
      component: SwatchFilter,
      content: {
        label: 'Inlay Width',
        hasSwatch: false,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: group.groove_group?.length > 0,
        fieldType: "groove_group_2",
        options: group.groove_group || [],
        selectedOption: getSelectedOption(group.groove_group),
        meta: getFilterMetaData(product, 'groove_group'),
        warning: getWarning(product, 'groove_group')
      }
    }
  ];

  const grooves = group.grooves || [];
  grooves.forEach((groove: any, i: any) => {
    items.push({
      title: 'Material',
      eventKey: 'material' + i,
      component: SwatchFilter,
      content: {
        label: 'Inlay Material',
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false, // show more removed after review
        isEnabled: groove.inlay?.length > 0,
        fieldType: `inlay_2:${i}:${groove.sku}`,
        options: groove.inlay || [],
        selectedOption: getSelectedOption(groove.inlay),
        meta: getFilterMetaData(product, 'grooves'),
        warning: getWarning(product, `inlay_${i}`)
      }
    })

    items.push({
      title: 'Finish',
      eventKey: 'finish' + i,
      component: SwatchFilter,
      content: {
        label: groove.inlay_finish.name,
        hasSwatch: true,
        showDefaultTitle: true,
        displayShowMoreOption: false,
        isEnabled: groove.inlay_finish?.items?.length > 0,
        fieldType: `inlay_finish_2:${i}:${groove.sku}`,
        options: groove.inlay_finish?.items || [],
        selectedOption: getSelectedOption(groove.inlay_finish.items),
        meta: getFilterMetaData(product, 'grooves'),
        warning: getWarning(product, `inlay_finish_${i}`)
      }
    })
  });

  items.push({
    title: 'Accent',
    eventKey: 'accent',
    component: SwatchFilter,
    content: {
      label: 'Accent Color',
      hasSwatch: true,
      showDefaultTitle: true,
      displayShowMoreOption: false, // show more removed after review
      isEnabled: group.mga?.length > 0,
      fieldType: "mga_2",
      options: group.mga || [],
      selectedOption: getSelectedOption(group.mga),
      meta: getFilterMetaData(product, 'mga'),
      warning: getWarning(product, 'mga')
    }
  });

  const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}

const getCerokoteItems = (product: any, group: any) => {
  const items: ITabs = [
    {
      title: (isActive) => isActive ? <><img src={crossWhite} alt="none"></img></> : <><img src={crossBlack} alt="none"></img></>,
      eventKey: 'none',
      actionPayload: { feature_group_2: 'N' },
      content: {
        label: 'None Tab',
        hasSwatch: false,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "feature_group_2",
        options: [],
        selectedOption: null,
        meta: null,
        warning: '',
        isEnabled: true,
      }
    },
    {
      title: 'Color',
      eventKey: 'cerakote',
      component: SwatchFilter,
      isSelected: true,
      content: {
        label: 'All cerakote',
        hasSwatch: true,
        showDefaultTitle: false,
        displayShowMoreOption: false,
        fieldType: "all_cerakote_2",
        options: group.all_cerakote,
        selectedOption: getSelectedOption(group.all_cerakote),
        meta: getFilterMetaData(product, 'all_cerakote'),
        warning: getWarning(product, 'all_cerakote'),
        isEnabled: true,
      }
    }
  ];

  const filtered = items.filter((tab: ITab) => tab.content?.isEnabled || tab.items?.length)
  return filtered;
}